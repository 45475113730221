<template>
    <div class="row justify-content-center m-auto align-items-center" style="height: 100vh;">
      <div class="col-12 col-sm-12 text-center">
        <h1>Halaman Tidak ditemukan</h1>
        <a href="/">Kembali Ke Halaman Utama</a>
      </div>
    </div>
</template>
<script>
  export default {
    name: 'register',
    data() {
      return {
        model: {
          name: '',
          email: '',
          password: ''
        }
      }
    }
  }
</script>
<style>
</style>
