<template>
  <div>
    <base-header class="pt-5 pt-md-8" type="gradient-success">
      <div class="notification-refresh border p-2 mb-4">
        <div class="row">
          <div class="col-md-8 col-12 d-flex align-items-center">
            <div class="logo-notification">
              <i class="fas fa-street-view"></i>
              Detail Pengawas
            </div>
          </div>
          <div class="col-md-4 col-12 d-flex align-items-center">
            <div class="information-notification ml-auto">
              <b-button
                v-b-tooltip.hover
                class="btn-sync ml-1 bg-success"
                size="sm"
                title="Refresh Data"
                @click="refreshDetailPengawas"
              >
                <i class="fas fa-sync-alt"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="card">
            <!-- /.card-header -->
            <div class="card-body">
              <TableData />
            </div>
          </div>
        </div>

        <div class="col-12 mt-3">
          <HeaderCardOutside
            title-header="Data Sekolah"
            @onaction="getSchools()"
          />
        </div>

        <div class="col-12 col-md-12 my-3">
          <div class="card">
            <!-- /.card-header -->
            <div class="card-body">
              <TableSchools />
            </div>
          </div>
        </div>
      </div>

      <b-button
        v-show="schools.selects.length"
        v-b-tooltip.hover
        class="btn-sync ml-1 bg-success scroll_top"
        :title="`Jadikan Binaan`"
        @click="onAddNaunganEventHandler"
      >
        Terpilih {{ schools.selects.length }} Sekolah
      </b-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Swal from "sweetalert2";
import HeaderCardOutside from "@/components/HeaderCardOutside.vue";

export default {
  name: "ManajemenPengawasDetailPage",
  data() {
    return {
      government_id: null
    };
  },
  computed: {
    ...mapState({
      schools: state => state.schools.data,
      employee: state => state.employee.data,
      detailPengawas: state => state.manajemenPengawas.data.detailPengawas
    }),
    ...mapGetters([
      "provinceNameGetters",
      "isRolesAdminGovermentGetters",
      "govermentIdGetters"
    ])
  },
  components: {
    HeaderCardOutside,
    TableData: () => import("./Table"),
    TableSchools: () =>
      import("@/views/Goverment/ManajemenPengawas/Schools/index.vue")
  },
  methods: {
    ...mapActions("manajemenPengawas", [
      "getManajemenPengawas",
      "getDetailPengawas",
      "postSyncNaunganSchoolsManajemenPengawas",
      "prepareSchoolsInPengawasSync",
      "prepareSchoolsToPengawas",
      "getDetailPengawasHaveSchools"
    ]),
    ...mapActions("schools", ["getSchools"]),
    ...mapMutations("schools", ["changeSchools"]),
    ...mapMutations("manajemenPengawas", [
      "changeManajemenPengawasDetailPengawas"
    ]),

    async refreshDetailPengawas() {
      this.getDetailPengawasHaveSchools({
        employeeId: this.detailPengawas.employeeId,
      });
    },

    async onAddNaunganEventHandler() {
      if (!this.schools.selects.length) {
        Swal.fire("Pemberitahuan", "Sekolah masih belum dipilih.", "info");
        return false;
      }
      const resultFormData = await this.prepareSchoolsToPengawas();
      const result = await this.postSyncNaunganSchoolsManajemenPengawas({
        hideMessage: true,
        employeeId: this.detailPengawas.employeeId,
        formData: resultFormData
      });
      if (result) {
        this.changeSchools({
          selects: []
        });
        this.getSchools();
      }
    }
  },
  async beforeMount() {
    const { employeeId } = this.$route.params;
    if (!employeeId || employeeId === "0") {
      this.$router.push({ name: "manajemenPengawas" });
    }
    this.changeManajemenPengawasDetailPengawas({
      employeeId: employeeId
    });

    this.$store.commit("manajemenPengawas/changeManajemenPengawas", {
      filterStatus: "",
      sort_field: "name",
      sort_dir: false,
      filter: ""
    });
    await this.getDetailPengawas(this.detailPengawas.employeeId);
    this.$store.commit("schools/changeFilterChildFrom", "detailPengawas");
    this.$store.commit("schools/changeSchools", {
      filterNotPengawas: "",
      filterType: "",
      filterCity: "",
      sort_field: "name",
      sort_dir: true,
      filter: ""
    });
    await this.getSchools();
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/custom/generate-token.scss";

.scroll_top {
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
}
</style>
