<template>
  <div>
    <!-- tempat untuk menampilkan pesan error -->
    <div>
      <b-alert
        v-for="(item, index) in agendaSurat.errors"
        :key="`notif_error_${index}`"
        dismissible
        show
        variant="danger"
      >
        <span class="alert-icon"><i class="ni ni-bell-55"></i></span>
        <span class="alert-text">{{ item.message }}</span>
      </b-alert>
    </div>

    <form
      id="formCreateAgendaSurat"
      class="form pb-2"
      @submit.prevent="submitData"
    >
      <div
        v-if="agendaSurat.typeAction === 'edit'"
        class="form-group text-center"
      >
        <label for="name_user">Nomor Agenda</label>
        <h3>{{ agendaSurat.formData.agenda_number }}</h3>
      </div>
      <div class="form-group">
        <label for="name_user">Tujuan</label>
        <el-autocomplete
          class="inline-input w-100"
          v-model="agendaSurat.formData.purpose"
          :fetch-suggestions="querySearch"
          placeholder="Isi Tentang Tujuan Agenda Surat..."
        ></el-autocomplete>
        <!--<input-->
        <!--  id="purpose"-->
        <!--  v-model="agendaSurat.formData.purpose"-->
        <!--  class="form-control"-->
        <!--  placeholder="Isi Tentang Tujuan Agenda Surat..."-->
        <!--  required-->
        <!--  type="text"-->
        <!--/>-->
      </div>

      <div class="form-group">
        <label for="nip">Perihal</label>
        <input
          id="subject"
          v-model="agendaSurat.formData.subject"
          class="form-control"
          placeholder="Isi Tentang Perihal Agenda Surat..."
          required
          type="text"
        />
      </div>

      <div class="form-group">
        <label for="email">Tanggal</label>
        <input
          id="date"
          v-model="agendaSurat.formData.date"
          class="form-control"
          placeholder="Isi data..."
          readonly
          required
          type="email"
        />
      </div>

      <button
        class="btn btn-primary pl-2 pr-2 float-right btn-sm"
        type="submit"
      >
        {{ agendaSurat.formData.id ? "Update" : "Simpan" }} Data
      </button>
    </form>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Swal from "sweetalert2";
import { ShowLoadingSwal } from "@/utils/Api";

export default {
  name: "DetailEmployee",
  props: ["onClose", "jenisModal"],
  computed: {
    ...mapState({
      agendaSurat: state => state.agendaSurat.data
    })
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("agendaSurat/changeAgendaSurat", { errors: [] });
  },
  methods: {
    ...mapActions("agendaSurat", [
      "putAgendaSurat",
      "postAgendaSurat",
      "actionEdit",
      "actionRead",
      "actionCreate",
      "actionDelete"
    ]),
    ...mapMutations("agendaSurat", ["changeFormDataAgendaSuratObjectAssign"]),

    querySearch(queryString, cb) {
      let links = this.agendaSurat.itemsPurposeAll.map(item => {
        return {
          value: item
        }
      });
      let results = queryString ? links.filter(this.createFilter(queryString)) : links;
      cb(results);
    },
    createFilter(queryString) {
      const teks = queryString ? queryString.toLowerCase() : "";
      return (link) => {
        if (link.value) {
          return link.value.toLowerCase().includes(teks);
        }
        return false;
      };
    },
    validasiFormData() {
      const { purpose, subject } = this.agendaSurat.formData;
      if (!purpose || !subject) {
        this.$message("Isian Pada Form harus diisi semua", "info");
        return false;
      }
      return true;
    },

    async submitData() {
      ShowLoadingSwal();

      let result = false;
      if (this.agendaSurat.typeAction === "edit") {
        result = await this.putAgendaSurat();
      } else {
        result = await this.postAgendaSurat();
      }
      Swal.close();
      if (result) {
        this.$emit("closeModal");
      }
    }
  },
  beforeDestroy() {
    this.actionRead();
  },
  watch: {
    "agendaSurat.formData.name": function(newValue) {
      console.log(newValue);
      if (!newValue) {
        return;
      }
      // Menghilangkan karakter non-alfanumerik dan spasi, lalu mengubah ke huruf kecil
      const formatResult = newValue
        .replace(/[^a-z0-9]/gi, "") // Menghilangkan karakter non-alfanumerik
        .replace(/\s/g, "") // Menghilangkan spasi
        .toLowerCase(); // Mengubah ke huruf kecil

      this.changeFormDataEmployeeObjectAssign({
        email: formatResult + "@diskola.id"
      });
    }
  }
};
</script>
