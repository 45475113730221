<script>
export default {
  name: "HeaderCardOutside",
  props: {
    titleHeader: {
      type: String,
      required: true
    },
  },
  methods: {
    emitAction(newPage) {
      this.$emit('onaction', newPage);
    },
  }

}
</script>

<template>
  <div class="notification-refresh border p-2">
    <div class="row">
      <div class="col-md-8 col-12 d-flex align-items-center">
        <div class="logo-notification">
          <i class="fas fa-street-view"></i>
          {{titleHeader}}
        </div>
      </div>
      <div class="col-md-4 col-12 d-flex align-items-center">
        <div class="information-notification ml-auto">
          <b-button
            v-b-tooltip.hover
            class="btn-sync ml-1 bg-success"
            size="sm"
            title="Refresh Data"
            @click="emitAction"
          >
            <i class="fas fa-sync-alt"></i>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>