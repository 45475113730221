<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8" type="gradient-success">
      <div class="notification-refresh border p-2 mb-4">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <div class="logo-notification">
              <i class="fas fa-address-card"></i>
              HAK AKSES {{ provinceNameGetters }}
            </div>
            <div class="information-notification ml-auto">
              <b-button
                v-b-tooltip.hover
                class="btn-sync ml-1 bg-success "
                size="sm"
                title="Refresh Data"
                @click="sinkrokinsasi()"
              >
                <i class="fas fa-sync-alt" title="Refresh Data"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-12">
          <div class="header-information d-flex align-items-center mb-3">
            <div class="header-info">
              <h1></h1>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- /.card-header -->
            <div class="card-body">
              <TableGenerate ref="items" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TableGenerate from "./Table/index.vue";
import { ROLES } from "@/services/employeeService";

export default {
  data() {
    return {
      token: null
    };
  },
  computed: {
    ...mapGetters([
      "provinceNameGetters",
      "govermentIdGetters",
      "rolesArrayGetters"
    ])
  },
  components: {
    TableGenerate
  },
  methods: {
    copyText() {
      let copyText = document.getElementById("generateToken");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");

      this.$Swal.fire({
        type: "success",
        title: "Token telah dicopy.",
        timer: 1000
      });
    },
    sinkrokinsasi() {
      this.$store.dispatch("stateUnitDepartment/getUnitDepartment");
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.rolesArrayGetters.includes(ROLES.kacabdin)) {
        vm.$router.replace({
          name: "anggotaEmployee",
          params: { id: vm.govermentIdGetters }
        });
      } else {
        next();
      }
    });
  },
  mounted() {}
};
</script>
<style lang="scss">
@import "@/assets/scss/custom/generate-token.scss";
</style>
