<template>
  <div>
    <div class="row d-flex mb-2">
      <div class="col-sm-4"></div>
      <div class="col-sm-4 text-right ml-auto">
        <el-button
          class="w-100 border-blue-1 col-blue"
          size="medium"
          @click="showmodal = true"
        >
          Tambah Data
        </el-button>
      </div>
    </div>
    <div class="row d-flex mb-2">
      <div class="col-sm-6">
        <h5 class="mt-2">
          {{ stateUnitDepartment.total }} dari
          {{ stateUnitDepartment.total }} data Hak Akses Ditampilkan
        </h5>
      </div>

      <!--<div class="col-sm-6 text-right ml-auto">
        <label class="mr-2 mt-2">{{ stateUnitDepartment.page }} / {{ stateUnitDepartment.page }}</label>
        <button
          :disabled="currentPage <= 1"
          class="btn btn-outline-primary btn-pagination btn-sm pr-3"
          @click="() => currentPage&#45;&#45;"
        >
          <span class="ml-1"><i class="fas fa-angle-left"></i></span>
        </button>
        <button
          :disabled="currentPage >= totalPage"
          class="btn btn-outline-primary btn-pagination btn-sm pl-3"
          @click="() => currentPage++"
        >
          <span class="mr-1"><i class="fas fa-angle-right"></i></span>
        </button>
      </div>-->
    </div>
    <div class="row mt-n2 ml-0 mr-0">
      <b-table
        :busy="stateUnitDepartment.loading"
        :fields="fields"
        :items="unitDepartmentGetters"
        :no-border-collapse="noCollapse"
        :sticky-header="false"
        class="table-kertas-kerja"
        hover
        responsive
        show-empty
        striped
      >
        <template #table-busy>
          <div class="text-center text-muted my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #empty>
          <p class="text-center">Tidak ditemukan data yang sesuai.</p>
        </template>

        <template #cell(no)="data">
          <span>{{ data.index + 1 }}</span>
          <!--  <span>{{ (currentPage - 1) * perPage + data.index + 1 }}</span>-->
        </template>

        <template #cell(name)="data">
          <span>{{ data.item.head_of_department.name }}</span>
        </template>

        <template #cell(email)="data">
          <span>{{ data.item.head_of_department.email }}</span>
        </template>

        <template #cell(phone)="data">
          <span>{{ data.item.head_of_department.phone }}</span>
        </template>

        <template #cell(city)="data">
          <div v-if="data.item.area_name.length">
            <b-badge
              v-for="(item, index) in data.item.area_name"
              :key="`cities_ud_${index}`"
              class="mr-1 text-with"
              variant="info"
            >
              {{ item.name }}
            </b-badge>
          </div>
          <b-badge v-else class="mr-1 text-with" variant="info">
            {{ data.item.city_name }}
          </b-badge>
        </template>

        <template v-slot:cell(aksi)="data">
          <router-link
            :to="{ name: 'anggotaEmployee', params: { id: data.item.id } }"
          >
            <b-button
              v-b-tooltip.hover
              class="btn btn-dark  btn-sm text-white"
              title="Data Employe"
            >
              <i class="fas fa-users"></i>
            </b-button>
          </router-link>
          <!--<button
            class="btn btn-danger  btn-sm text-white"
            title="Reset Password"
            v-b-tooltip.hover
            @click="deleteToken(data.item.id)"
          >
            <i class="fas fa-unlock-alt"></i>
          </button>-->
        </template>
      </b-table>
    </div>
    <Modal
      :onClose="() => (showmodal = false)"
      :show="showmodal"
      :title="`Tambah Data`"
      width="50%"
    >
      <ModalDetail
        v-if="showmodal"
        :onClose="() => (showmodal = false)"
        @closeModal="() => (showmodal = false)"
      />
    </Modal>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "",
  props: {
    searchSchoolType: {
      type: String,
      required: false
    },
    search: {
      type: String,
      required: false
    }
  },
  components: {
    Modal: () => import("@/components/ModalUI"),
    ModalDetail: () => import("./ Modal")
  },
  computed: {
    ...mapState({
      stateUnitDepartment: state => state.stateUnitDepartment.data,
      cities: state => state.cities.data
    }),
    ...mapGetters("stateUnitDepartment", ["unitDepartmentGetters"])
  },
  data() {
    return {
      fields: [
        { key: "no", sortable: false, width: "50px" },
        { key: "name", sortable: false, width: "150px", minWidth: "100px" },
        { key: "email", sortable: false },
        { key: "phone", sortable: false },
        { key: "city", sortable: false },
        { key: "aksi", sortable: false, width: "150px", minWidth: "100px" }
      ],
      stickyHeader: true,
      noCollapse: true,
      showmodal: false
    };
  },
  methods: {
    ...mapActions("stateUnitDepartment", ["getUnitDepartment"]),
    ...mapActions("cities", ["getCities"]),
    deleteToken(e) {
      console.log(e);
    }
  },
  mounted() {
    if (!this.cities.length) {
      this.getCities(this.$store.getters.provinceIdGetters);
    }
    this.getUnitDepartment();
  }
};
</script>
<style lang="scss">
@import "../../../../assets/scss/custom/_rkas-table.scss";
</style>
