<template>
  <div>
    <!-- tempat untuk menampilkan pesan error -->
    <div>
      <b-alert
        v-for="(item, index) in employee.errors"
        :key="`notif_error_${index}`"
        dismissible
        show
        variant="danger"
      >
        <span class="alert-icon"><i class="ni ni-bell-55"></i></span>
        <span class="alert-text">{{ item.message }}</span>
      </b-alert>
    </div>
    <form
      id="formCreateEmployee"
      class="form pb-2"
      @submit.prevent="submitData"
    >
      <div v-if="!isKacabdinGetters && jenisModal==='Pengawas'" class="form-group">
        <label for="province_name">Jenjang Sekolah * (roles)</label>
        <el-select
          v-model="employee.formData.roles"
          aria-autocomplete="none"
          class="w-100"
          multiple
          placeholder="Pilih Jenis Sekolah"
        >
          <el-option
            v-for="item in employee.selectTypeSchool"
            :key="`city_${item.value}`"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <small class="form-text text-muted">
          Jenjang sekolah, akan berpengaruh pada monitoring sesuai jenjang yang
          anda pilih
        </small>
      </div>

      <div v-if="!isKacabdinGetters && jenisModal==='Admin'" class="form-group">
        <label for="province_name">Pilih Cabang Kota/Kabupaten *</label>
        <el-select
          v-model="employee.formData.city_id"
          aria-autocomplete="none"
          class="w-100"
          filterable
          placeholder="Pilih Kota/Kabupaten"
        >
          <el-option
            v-for="item in regionEmployeeNameGetters"
            :key="`city_${item.name}`"
            :label="item.name"
            :value="item.uuid"
          >
          </el-option>
        </el-select>
        <small class="form-text text-muted">
          Jenjang sekolah, akan berpengaruh pada monitoring sesuai jenjang yang
          anda pilih
        </small>
      </div>

      <div class="form-group">
        <label for="name_user">Nama Pengguna * (name)</label>
        <input
          id="name_user"
          v-model="employee.formData.name"
          class="form-control"
          placeholder="Isi data..."
          required
          type="text"
        />
        <small class="form-text text-muted">
          Data Pengguna untuk melihat data pada monitoring
        </small>
      </div>

      <div class="form-group">
        <label for="nip">NIP *</label>
        <input
          id="nip"
          v-model="employee.formData.nip"
          class="form-control"
          placeholder="Isi data..."
          required
          type="text"
        />
        <small class="form-text text-muted">
          Pastikan NIP tidak sama dengan data yang sudah ada pada sistem ini.
        </small>
      </div>

      <div class="form-group">
        <label for="email">Email *</label>
        <input
          id="email"
          v-model="employee.formData.email"
          class="form-control"
          placeholder="Isi data..."
          required
          type="email"
        />
      </div>

      <div class="form-group">
        <label for="phone">Nomor Telpon *</label>
        <input
          id="phone"
          v-model="employee.formData.phone"
          class="form-control"
          placeholder="Isi data..."
          required
          type="text"
        />
      </div>

      <div v-if="employee.typeAction === 'create'" class="form-group">
        <label for="password">Password Default</label>
        <input
          id="password"
          :placeholder="employee.formData.password || 'diskola2024'"
          class="form-control"
          readonly
          required
          type="text"
        />
      </div>

      <button
        class="btn btn-primary pl-2 pr-2 float-right btn-sm"
        type="submit"
      >
        {{ employee.formData.id ? "Update" : "Simpan" }} Data
      </button>
    </form>
  </div>
</template>
<script>
import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
import Swal from "sweetalert2";
import {ShowLoadingSwal} from "@/utils/Api";

export default {
  name: "DetailEmployee",
  props: ["onClose", "jenisModal"],
  computed: {
    ...mapState({
      employee: state => state.employee.data
    }),
    ...mapGetters("employee", ["isKacabdinGetters", "regionEmployeeNameGetters"])
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("employee/changeEmployee", { errors: [] });
  },
  methods: {
    ...mapActions("employee", [
      "putEmployee",
      "postEmployee",
      "actionEdit",
      "actionRead",
      "actionCreate",
      "actionDelete"
    ]),
    ...mapMutations("employee", ["changeFormDataEmployeeObjectAssign"]),

    validasiFormData() {
      const { roles } = this.employee.formData;
      if (!roles.length) {
        this.$message("Pilihan Jenjang sekolah harus diisi.", "info");
        return false;
      }
      return true;
    },

    async submitData() {
      if (this.jenisModal==='Pengawas') {
        if (!this.validasiFormData()) {
          return false;
        }
      }

      ShowLoadingSwal();

      let result = false;
      if (this.employee.typeAction === "edit") {
        result = await this.putEmployee({jenisModal: this.jenisModal});
      } else {
        result = await this.postEmployee({jenisModal: this.jenisModal});
      }
      Swal.close();
      if (result) {
        this.$emit("closeModal");
      }
    }
  },
  watch: {
    "employee.formData.name": function(newValue) {
      console.log(newValue);
      if (!newValue) {
        return;
      }
      // Menghilangkan karakter non-alfanumerik dan spasi, lalu mengubah ke huruf kecil
      const formatResult = newValue
        .replace(/[^a-z0-9]/gi, '') // Menghilangkan karakter non-alfanumerik
        .replace(/\s/g, '')         // Menghilangkan spasi
        .toLowerCase();             // Mengubah ke huruf kecil


      this.changeFormDataEmployeeObjectAssign({ email: formatResult+"@diskola.id" });
    }
  }
};
</script>
