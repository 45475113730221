<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="notification-refresh border p-2 mb-4">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <div class="logo-notification">
              <i class="fas fa-street-view"></i>
              Wilayah <CitiesAreaCoverage />
            </div>
            <div class="information-notification ml-auto">
              <b-button
                size="sm"
                class="btn-sync ml-1 bg-success "
                title="Refresh Data"
                v-b-tooltip.hover
                @click="getEmployee({id: government_id})"
              >
                <i class="fas fa-sync-alt"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-12">
          <div class="header-information d-flex align-items-center mb-3">
            <div class="header-info">
              <h1></h1>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- /.card-header -->
            <div class="card-body">
              <TableEmployee />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
export default {
  name: "HakAksesEmployee",
  data() {
    return {
      government_id: null,
    };
  },
  computed: {
    ...mapState({
      employee: (state) => state.employee.data,
    }),
    ...mapGetters(["provinceNameGetters"]),
  },
  components: {
    TableEmployee: () => import("./Table"),
    CitiesAreaCoverage: () => import("./CitiesAreaCoverage"),
  },
  methods: {
    ...mapActions("employee", ["getEmployee"]),
  },
  beforeMount() {
    try {
      this.government_id = this.$route.params.id;
      this.$store.commit("employee/changeEmployee", {government_id: this.government_id});
    } catch (e) {
      console.log("params tidak ditemukan");
      this.$router.push({name: "anggotaGoverment"});
    }
  },
  mounted() {
    this.getEmployee({id: this.government_id});
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/custom/generate-token.scss";
</style>
